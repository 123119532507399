<template>
  <div class="stepContent commonClass">
    <h3 class="titHead">课题详情</h3>
    <div class="detail">
      <h4 class="title">基本信息</h4>
      <div class="desc">
        <span>课题编号：{{ activeInfo.number }}</span>
        <span>申请日期：{{ activeInfo.startTime | formatDay }}</span>
        <p v-if="activeInfo.detail">
          <span>负责人：{{ activeInfo.detail.director }}</span>
          <span>负责人电话：{{ activeInfo.detail.contact }}</span>
          <span>负责人邮箱：{{ activeInfo.detail.email }}</span>
        </p>
        <p>实验名称：{{ activeInfo.name }}</p>
        <p v-if="activeInfo.detail">实验摘要：{{ activeInfo.detail.summary }}</p>
      </div>
      <h4 class="title">合笼成员</h4>
      <div class="desc">
        <span>负责人：{{ memberPrincipal.name }}</span>
        <span>负责人电话：{{ memberPrincipal.phone }}</span>
        <span>负责人邮箱：{{ memberPrincipal.email }}</span>
      </div>
      <el-table
          :data="memberList"
          border
          class="tableMember"
          :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
          max-height="250">
        <el-table-column label="项目组成员姓名" width="180" prop="name">
        </el-table-column>
        <el-table-column label="相关专业证书编号" min-width="100" show-overflow-tooltip prop="certNum">
        </el-table-column>
        <el-table-column label="工作内容" show-overflow-tooltip prop="jobContent">
        </el-table-column>
        <el-table-column label="电话" prop="phone">
        </el-table-column>
        <el-table-column label="邮箱" prop="email">
        </el-table-column>
      </el-table>
      <h4 class="title">课题动物</h4>
      <div class="desc">
        <p>
          <template v-if="activeInfo.isLabAni==1">
            <span>来源：实验室</span>
          </template>
          <template v-if="activeInfo.isLabAni!=1&&activeInfo.aniOrder&&activeInfo.aniOrder.aniSupply">
            <span>来源：{{ activeInfo.aniOrder.aniSupply.supplier }}</span>
            <span>许可证：{{ activeInfo.aniOrder.aniSupply.prodCert }}</span>
          </template>
          <span>动物品系：{{ activeInfo.strain }}</span>
        </p>
        <template v-if="activeInfo.aniOrder">
          <p>
            <span>笼舍数量：{{ activeInfo.cageNum }}</span>
            <span>等级：{{ activeInfo.aniOrder.grade == 0 ? '普通' : activeInfo.grade == 1 ? 'SPF' : '清洁级' }}</span>
            <span>是否特殊基因合笼：{{ activeInfo.aniOrder.isGene == 1 ? '是' : '否' }}</span>
          </p>
          <p v-if="activeInfo.aniOrder.isGene==1">基因类型：{{ activeInfo.aniOrder.geneKeys }}</p>
        </template>
      </div>
      <el-table
          v-if="activeInfo.aniOrder" :data="activeInfo.aniOrder.aniOrderDetailList"
          border
          class="tableMember"
          :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}">
        <el-table-column label="基因类型" v-if="activeInfo.aniOrder.isGene==1">
          <el-table-column :label="item.title" v-for="(item,ind) in typeList" :key="ind">
            <template slot-scope="scope">
              {{ scope.row.typeList[ind].type }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="性别">
          <template slot-scope="scope">
            {{ scope.row.gender == 0 ? '♀' : '♂' }}
          </template>
        </el-table-column>
        <el-table-column label="数量" show-overflow-tooltip prop="count">
        </el-table-column>
        <el-table-column label="体重(g)" prop="weight">
        </el-table-column>
        <el-table-column label="周龄" prop="weekDays">
        </el-table-column>
      </el-table>
      <h4 class="title">实验时间</h4>
      <div class="desc">
        <span>计划开始时间：{{ activeInfo.startTime  | formatDay }}</span>
      </div>
      <h4 class="title">实验备注</h4>
      <div class="desc" v-if="activeInfo.detail">
        <p>实验备注：{{ activeInfo.detail.remark }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "detailSub",
  props: ["id"],
  data() {
    return {
      activeInfo: {},
      memberList: [],
      memberPrincipal: {},
      typeList: [],
    }
  },
  async mounted() {
    let that = this;
    await that.$get("/subject/detail/" + this.id).then((res) => {
      if (res.status == 200) {
        that.activeInfo = res.data

        res.data.memberList.forEach(item => {
          if (item.isPrincipal == 1) {
            this.memberPrincipal.name = res.data.dirNickname
            this.memberPrincipal.phone = item.phone
            this.memberPrincipal.email = item.email

            // this.memberPrincipal = item
          } else {
            this.memberList.push(item)
          }
        })
        // 表格内的基因类型
        res.data.aniOrder.aniOrderDetailList.forEach(obj => {
          obj.typeList = JSON.parse(obj.gene)
        })

        // 赋值typeList
        let arr = this.activeInfo.aniOrder.geneKeys.split(',')
        arr.forEach(item => {
          this.typeList.push({
            title: item
          })
        })
      }
    }).catch(() => {
      this.$message.error('获取数据失败')
    })
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";
</style>
